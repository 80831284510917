<template>
    <div class="d-lg-none">
        <div class="row pt-3 mb-5">
            <div class="col-12 col-lg-6">
                <validated-input class="mb-0" label="Search" v-model="ext_search"/>
            </div>
            <div class="col-12 mt-2">
                <validated-select @blur="loadData" v-model="mark_as_read" :options="markReadOptions"
                                  label="Read Type"/>
            </div>
            <div class="col-12 mt-2">
                <btn :loading="downloading" loading-text="Downloading..." text="Download"
                     size="md" class="btn-block" @click="downloadExcel"/>
            </div>
        </div>

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-2">
                <div class="mb-5">
                    <div class="bs-3">
                        <div class="row b-1 fs--1 mt-2 m-0">

                            <span v-if="i.auction.auction_id" class="b-1 w-100 col-12 py-2">
                            <div class="p-1 font-weight-bold">
                                <span class="font-inter-medium">Auction ID:</span>
                                <span class="ml-2"> {{ i.auction.auction_id }} </span>
                            </div>
                        </span>

                            <span v-if="i.auction.title" class="b-1 w-100 col-12 py-2">
                            <div class="p-1 font-weight-bold">
                                <span class="font-inter-medium">Auction Title:</span>
                                <span class="ml-2"> {{ i.auction.title }} </span>
                            </div>
                        </span>

                            <span v-if="i.name" class="b-1 w-100 col-12 py-2">
                            <div class="p-1 ">
                                <span class="font-inter-medium">Name:</span>
                                <span class="ml-2"> {{ i.name }} </span>
                            </div>
                        </span>
                            <span v-if="i.email" class="b-1 w-100 col-12 py-2">
                            <div class="p-1">
                                <span class="font-inter-medium">E-Mail:</span> <span class="ml-2">
                                    {{ i.email }}
                                </span>
                            </div>
                        </span>

                            <span v-if="i.phone" class="b-1 w-100 col-12 py-2">
                            <div class="p-1">
                                <span class="font-inter-medium">Phone:</span>
                                <span class="ml-2"> {{ i.phone }} </span>
                            </div>
                        </span>

                            <span v-if="i.created_date" class="b-1 w-100 col-12 py-2">
                            <div class="p-1">
                                <span class="font-inter-medium">Enquired On:</span>
                                <span class="ml-2"> {{ i.created_date }} </span>
                            </div>
                        </span>

                            <span v-if="i.request_call_back" class="b-1 w-100 col-12 py-2">
                            <div class="p-1">
                                <span class="font-inter-medium">Request call back:</span>
                                <span class="ml-2" v-if="i.request_call_back">Yes</span>
                                <span class="ml-2" v-else>No</span>
                            </div>
                        </span>

                            <span class="b-1 w-100 col-12 py-2">
                            <div class="p-1">
                                <span class="font-inter-medium">Platform:</span>
                                <span class="ml-2" v-if="!i.auction.is_third_party">Bank e Auctions India.com</span>
                                <span class="ml-2" v-else>Other</span>
                            </div>
                        </span>
                            <span class="b-1 col-12">
                    <div class="p-1 fl-x fl-j-c py-3 btn-group-tight">
                        <edit-btn @click="viewDetails(i.id)" icon="" size="sm" class="px-4" text="Details"/>
                        <btn color="secondary" :disabled="markAsReadLoading" :loading="markAsReadLoading" size="sm"
                                 class="fa fa-archive" @click="markAsRead(i.id)"/>
                    </div>
                </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../data/urls';
import _ from 'lodash';

export default {
    name : 'EnquiryMobileView',

    data () {
        return {
            mark_as_read    : false,
            markReadOptions : [
                {
                    label : 'Read',
                    value : true
                },
                {
                    label : 'Unread',
                    value : false
                }
            ],
            prev              : '',
            next              : '',
            page              : '1',
            per_page          : '10',
            downloading       : false,
            ext_search        : '',
            loading           : true,
            error             : false,
            details           : null,
            markAsReadLoading : false
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    watch : {
        ext_search : _.debounce(function (newVal) {
            this.loadData();
        }, 2000)
    },

    mounted () {
        this.loadData();
    },

    methods : {
        viewDetails (id) {
            this.$router.push('/enquiry-list/' + id + '/details/');
        },
        async markAsRead (id) {
            this.markAsReadLoading = true;
            const response = await axios.form(urls.userList.markAsRead, {
                id : id
            });
            const json = response.data;
            if (json.success === true) {
                this.$notify('Marked as Read', 'Success', {
                    type : 'success'
                });
            } else {
                this.$notify('Could not process the request, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.markAsReadLoading = false;
            this.loadData();
        },
        downloadExcel () {
            const that = this;
            that.downloading = true;
            axios.get(urls.userList.enquiryListDownload, {
                params : {
                    ext_search   : that.ext_search,
                    mark_as_read : that.mark_as_read
                }
            }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    window.open(json.url);
                } else {
                    this.$notify('Could not download the enquiries, Please try again later', 'Error', {
                        type : 'danger'
                    });
                }
                that.downloading = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.downloading = false;
                that.error = true;
            });
        },
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.userList.enquiryList, {
                params : {
                    page         : that.page,
                    per_page     : that.per_page,
                    ext_search   : that.ext_search,
                    mark_as_read : that.mark_as_read
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.setPagination();
                that.loading = false;
                that.error = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        }

    }
};
</script>

<style scoped>

</style>
