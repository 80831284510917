<template>
    <inner-page-card class="mb-5" heading="Auction Enquiry List">
        <div class="row pt-3">
            <div class="col-12 col-lg-3 d-sm-none-lg-block">
                <validated-input placeholder="Search" v-model="ext_search"/>
            </div>
            <div class="col-12 col-lg-3 d-sm-none-lg-block">
                <btn :loading="downloading" loading-text="Downloading..." text="Download"
                     size="md" class="px-4" @click="downloadExcel"/>
            </div>
        </div>
        <tabs v-if="currentUser.is_superuser || currentUser.is_ventura_admin"
              class="d-sm-none-lg-block" align="center">
            <tab title="Unread Enquiries">
                <custom-vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table"
                                  :extra-params="{ext_search:ext_search, mark_as_read : false}"
                                  :show-search-box="false">
                    <template #is_third_party="{rowData}">
                        <span v-if="!rowData.auction.is_third_party">Bank e Auctions India.com</span>
                        <span v-else>Other</span>
                    </template>
                    <template #request_call_back="{rowData}">
                        <span v-if="rowData.request_call_back">Yes</span>
                        <span v-else>No</span>
                    </template>
                    <template #actions="{rowData}">
                        <div class="btn-group-tight">
                            <btn color="primary" size="sm" class="fa fa-eye" @click="viewDetails(rowData.id)"/>
                            <btn color="secondary" :disabled="markAsReadLoading" :loading="markAsReadLoading" size="sm"
                                 class="fa fa-archive" @click="markAsRead(rowData.id)"/>
                        </div>
                    </template>
                </custom-vue-table>
            </tab>
            <tab title="Read Enquiry">
                <custom-vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table"
                                  :extra-params="{ext_search:ext_search, mark_as_read : true}"
                                  :show-search-box="false">
                    <template #is_third_party="{rowData}">
                        <span v-if="!rowData.auction.is_third_party">Bank e Auctions India.com</span>
                        <span v-else>Other</span>
                    </template>
                    <template #request_call_back="{rowData}">
                        <span v-if="rowData.request_call_back">Yes</span>
                        <span v-else>No</span>
                    </template>
                    <template #actions="{rowData}">
                        <div class="btn-group-tight">
                            <btn color="primary" size="sm" class="fa fa-eye" @click="viewDetails(rowData.id)"/>
                        </div>
                    </template>
                </custom-vue-table>
            </tab>
        </tabs>
        <div v-else class="text-center py-7">
            <h4 class="font-inter-bold">Permission Denied!</h4>
            <p class="">Sorry, You have no permission to view this page.</p>
        </div>
        <enquiries-mobile-view :ext_search="ext_search" class="d-lg-none"/>
    </inner-page-card>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import EnquiriesMobileView from '../enquiry/EnquiryMobileView';
import axios from 'secure-axios';

export default {
    name       : 'EnquiryList',
    components : { EnquiriesMobileView },
    computed   : {
        ...mapGetters(['currentUser'])
    },
    data () {
        return {
            downloading       : false,
            mark_as_read      : false,
            markAsReadLoading : false,
            listUrl           : urls.userList.enquiryList,
            ext_search        : '',
            fields            : [
                {
                    name      : 'auction.auction_id',
                    sortField : 'auction.auction_id',
                    title     : 'Auction ID'
                },
                {
                    name      : 'auction.title',
                    sortField : 'auction.title',
                    title     : 'Auction Title'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'email',
                    sortField : 'email',
                    title     : 'E-Mail ID'
                },
                {
                    name      : 'phone',
                    sortField : 'phone',
                    title     : 'Phone'
                },
                {
                    name      : '__slot:request_call_back',
                    sortField : 'request_call_back',
                    title     : 'Request call back'
                },
                {
                    name      : '__slot:is_third_party',
                    sortField : 'is_third_party',
                    title     : 'Platform'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewDetails (id) {
            this.$router.push('/enquiry-list/' + id + '/details/');
        },
        async markAsRead (id) {
            this.markAsReadLoading = true;
            const response = await axios.form(urls.userList.markAsRead, {
                id : id
            });
            const json = response.data;
            if (json.success === true) {
                this.$notify('Marked as Read', 'Success', {
                    type : 'success'
                });
            } else {
                this.$notify('Could not process the request, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.markAsReadLoading = false;
            this.$refs.table.refreshTable();
        },
        downloadExcel () {
            const that = this;
            that.downloading = true;
            axios.get(urls.userList.enquiryListDownload, {
                params : {
                    ext_search   : that.ext_search,
                    mark_as_read : that.mark_as_read
                }
            }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    window.open(json.url);
                } else {
                    this.$notify('Could not download the enquiries, Please try again later', 'Error', {
                        type : 'danger'
                    });
                }
                that.downloading = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.downloading = false;
                that.error = true;
            });
        }
    }
};
</script>

<style scoped>

</style>
